import React from 'react';
import { Container, Typography, List, ListItem } from '@material-ui/core';

const TermsOfService = () => {
  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        Terms of Service
      </Typography>
      <Typography variant="h4" gutterBottom>
        1. Introduction
      </Typography>
      <Typography paragraph>
        Welcome to StatFactory.io. These Terms of Use ("Terms") govern your access to and use of our sports data customized reporting service ("Service"). By accessing and using our Service, you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, please do not use the Service.
      </Typography>

      <Typography variant="h4" gutterBottom>
        2. Service Description
      </Typography>
      <Typography paragraph>
        The Service provides users with access to a comprehensive database of sports statistics in select leagues. Users can create customized reports, view game reports with head-to-head comparisons, download data for personal analysis, and other such services that will be provided to the user.
      </Typography>

      <Typography variant="h4" gutterBottom>
        3. User Eligibility
      </Typography>
      <Typography paragraph>
        You must be at least 18 years old and have the legal capacity to enter into contracts to use the Service.
      </Typography>

      <Typography variant="h4" gutterBottom>
        4. User Account
      </Typography>
      <Typography paragraph>
        To access certain features of the Service, you may need to create an account. You are responsible for maintaining the confidentiality of your account and password and for all activities under your account. Notify us immediately of any unauthorized use of your account.
      </Typography>

      <Typography variant="h4" gutterBottom>
        5. Fees and Payment
      </Typography>
      <Typography paragraph>
        The Service requires a monthly subscription fee. You agree to pay all fees and taxes associated with your use of the Service. We reserve the right to change fees with reasonable notice.
      </Typography>

      <Typography variant="h4" gutterBottom>
        6. Content and Intellectual Property
      </Typography>
      <Typography paragraph>
        The content and information available through the Service, including sports data and software, are protected by intellectual property laws. You agree not to misuse any content obtained from the Service, except as permitted by these Terms or with our prior written consent.
      </Typography>

      <Typography variant="h4" gutterBottom>
        7. User Conduct
      </Typography>
      <Typography paragraph>
        You agree to use the Service lawfully and not to violate any applicable laws. Prohibited actions include:
      </Typography>
      <List>
        <ListItem>Damaging or impairing the Service or any connected network.</ListItem>
        <ListItem>Interfering with other users' use of the Service.</ListItem>
        <ListItem>Attempting unauthorized access to the Service.</ListItem>
        <ListItem>Collecting other users' data without consent.</ListItem>
        <ListItem>Transmitting harmful code.</ListItem>
        <ListItem>Engaging in illegal activities through the Service.</ListItem>
      </List>

      <Typography variant="h4" gutterBottom>
        8. Disclaimers
      </Typography>
      <Typography paragraph>
        The Service is provided "as is" and "as available," without warranties of any kind. We are not liable for any damages arising from your use of the Service.
      </Typography>

      <Typography variant="h4" gutterBottom>
        9. Limitation of Liability
      </Typography>
      <Typography paragraph>
        Our liability for damages related to the Service is limited to the amount you paid for the Service.
      </Typography>

      <Typography variant="h4" gutterBottom>
        10. Termination
      </Typography>
      <Typography paragraph>
        We may terminate your access to the Service at any time, for any reason, without notice.
      </Typography>

      <Typography variant="h4" gutterBottom>
        11. Governing Law
      </Typography>
      <Typography paragraph>
        These Terms shall be governed by the laws of Indiana, without regard to conflict of laws principles.
      </Typography>

      <Typography variant="h4" gutterBottom>
        12. Entire Agreement
      </Typography>
      <Typography paragraph>
        These Terms constitute the entire agreement between you and StatFactory.io regarding the Service and supersede all prior communications.
      </Typography>

      <Typography variant="h4" gutterBottom>
        13. Severability
      </Typography>
      <Typography paragraph>
        If any provision of these Terms is held invalid, the remaining provisions will continue in effect.
      </Typography>

      <Typography variant="h4" gutterBottom>
        14. Waiver
      </Typography>
      <Typography paragraph>
        No waiver of any term is deemed a further or continuing waiver of such term or any other term.
      </Typography>
    </Container>
  );
};

export default TermsOfService;
