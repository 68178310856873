import React from 'react';
import { Container, Typography, Link, List, ListItem } from '@material-ui/core';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Typography variant="h2" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="h4" gutterBottom>
        1. Introduction
      </Typography>
      <Typography paragraph>
        Welcome to StatFactory.io. We are committed to protecting the privacy and security of our users' data. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your information when you use our service.
      </Typography>

      <Typography variant="h4" gutterBottom>
        2. Information Collection and Use
      </Typography>
      <Typography paragraph>
        We collect various types of information for various purposes to provide and improve our service to you.
      </Typography>
      <Typography variant="h5" gutterBottom>
        Personal Data
      </Typography>
      <Typography paragraph>
        While using our service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). This may include, but is not limited to, your email address, name, and phone number.
      </Typography>
      <Typography variant="h5" gutterBottom>
        Usage Data
      </Typography>
      <Typography paragraph>
        We may also collect information on how the service is accessed and used ("Usage Data"). This Usage Data may include information such as your computer's IP address, browser type, browser version, the pages of our service that you visit, the time and date of your visit, and other diagnostic data.
      </Typography>

      <Typography variant="h4" gutterBottom>
        3. Cookies and Tracking Data
      </Typography>
      <Typography paragraph>
        We use cookies and similar tracking technologies to track activity on our service and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our service.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Cookie Policy
      </Typography>
      <Typography variant="h4" gutterBottom>
        1. Introduction
      </Typography>
      <Typography paragraph>
        StatFactory.io uses cookies to improve your experience while you navigate through our website. By using the website, you consent to the use of cookies in accordance with this Cookie Policy.
      </Typography>

      <Typography variant="h4" gutterBottom>
        2. What are Cookies?
      </Typography>
      <Typography paragraph>
        Cookies are small text files stored on your device (computer, tablet, or mobile phone) when you visit a website. They help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.
      </Typography>

      <Typography variant="h4" gutterBottom>
        3. How Do We Use Cookies?
      </Typography>
      <Typography paragraph>
        As most online services, our website uses first-party and third-party cookies for several purposes. First-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data. The third-party cookies used on our websites are mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website.
      </Typography>

      <Typography variant="h4" gutterBottom>
        4. What Types of Cookies Do We Use?
      </Typography>
      <List>
        <ListItem>
          <Typography><strong>Essential:</strong> Some cookies are essential for you to be able to experience the full functionality of our site.</Typography>
        </ListItem>
        <ListItem>
          <Typography><strong>Analytics:</strong> These cookies store information like the number of visitors to the website, the number of unique visitors, which pages of the website have been visited, the source of the visit, etc.</Typography>
        </ListItem>
        <ListItem>
          <Typography><strong>Functional:</strong> These are the cookies that help certain non-essential functionalities on our website.</Typography>
        </ListItem>
        <ListItem>
          <Typography><strong>Advertisements:</strong> StatFactory.io does not allow advertising and does not use advertising cookies.</Typography>
        </ListItem>
      </List>

      <Typography variant="h4" gutterBottom>
        5. How Can You Control Cookies?
      </Typography>
      <Typography paragraph>
        You can manage your cookies preferences by adjusting your browser settings. You can choose to disable cookies or receive a notification when a new cookie is sent to your device.
      </Typography>

      <Typography variant="h4" gutterBottom>
        6. More Information
      </Typography>
      <Typography paragraph>
        For more information about the cookies we use, please contact us at <Link href="mailto:customerservice@statfactory.io">customerservice@statfactory.io</Link>.
      </Typography>

      <Typography variant="h4" gutterBottom>
        4. Use of Data
      </Typography>
      <Typography paragraph>
        StatFactory will not sell your data, trade your data, or otherwise use it for business development. We believe that your user experience with us is limited to us. We believe that the monetization of our customers’ personal information is a breach of the trust you place in us.
      </Typography>
      <Typography paragraph>
        StatFactory.io may use the collected data for various purposes:
      </Typography>
      <List>
        <ListItem>To provide and maintain our service</ListItem>
        <ListItem>To notify you about changes to our service</ListItem>
        <ListItem>To allow you to participate in interactive features of our service when you choose to do so</ListItem>
        <ListItem>To provide customer support</ListItem>
        <ListItem>To gather analysis or valuable information so that we can improve our service</ListItem>
        <ListItem>To monitor the usage of our service</ListItem>
        <ListItem>To detect, prevent, and address technical issues</ListItem>
      </List>

      <Typography variant="h4" gutterBottom>
        5. Data Security
      </Typography>
      <Typography paragraph>
        The security of your data is important to us. We strive to use commercially acceptable means to protect your Personal Data, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure.
      </Typography>

      <Typography variant="h4" gutterBottom>
        6. Changes to This Privacy Policy
      </Typography>
      <Typography paragraph>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
      </Typography>

      <Typography variant="h4" gutterBottom>
        7. Contact Us
      </Typography>
      <Typography paragraph>
        If you have any questions about this Privacy Policy, please contact us at <Link href="mailto:customerservice@statfactory.io">customerservice@statfactory.io</Link>.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
