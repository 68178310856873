import React, { useState, useContext, useEffect } from 'react';
import { 
  Typography,
  Paper, 
  Select, 
  MenuItem, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  IconButton,
  Breadcrumbs,
  Box,
  Tooltip

} from '@material-ui/core';
import { DragIndicator, Delete } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DashboardContext } from './v3Context.js';
import { makeStyles } from '@material-ui/core/styles';

import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import EditableTypography from './EditableHeaderDescription.jsx';

const IndexReference = ( index ) => {
  switch (index) {
    case 0:
      return ['Full Season', 'Total']
    case 1:
      return ['Full Season', 'As Away / Home']
    case 2:
      return ['Full Season', 'As Underdog / Favorite']
    case 3:
      return ['Full Season', 'Conference / Non-Conference']
    case 4:
      return ['Last 3 Games', 'Total']
    case 5:
      return ['Last 3 Games', 'As Away / Home']
    case 6:
      return ['Last 3 Games', 'As Underdog / Favorite']
    case 7:
      return ['Last 3 Games', 'Conference / Non-Conference']
  
    default:
      break;
  }
}

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    padding: theme.spacing(0),
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  tableContainer: {
    width: '100%',
    padding: theme.spacing(0),
  },
  stickyHeader: {
    padding: theme.spacing(0),
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
  eventRow: {
    cursor: 'pointer',
    padding: theme.spacing(0),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  cell: {
    padding: theme.spacing(0),
    textAlign: 'center',
  },
  vcell: {
    padding: theme.spacing(0),
    textAlign: 'right',
  },
  hcell: {
    padding: theme.spacing(10),
    textAlign: 'left',
  },
  listItem: {
    padding: theme.spacing(0, 1), // Adjust padding here
    margin: theme.spacing(0, 0), // Adjust margin here
    overflow: 'hidden',
 },
  listItemIcon: {
    minWidth: '30px', // Reduce the minimum width
  },
  listItemText: {
    margin: 0, // Remove margin
  },
  breadcrumbs: {
    margin: theme.spacing(0), // Remove margin
    padding: theme.spacing(0), // Remove padding
  },
  secondaryText: {
  },
  addIcon: {
    margin: theme.spacing(2), // Remove margin
    // padding: theme.spacing(1), // Remove padding
    fontSize: '40px',  // Adjust the size as needed
    color: 'rgba(0, 200, 0, 1)',  // Darker green on hover
    cursor: 'pointer',
    zIndex: 1000,  // Ensure it's above other elements
    '&:hover': {
      color: 'rgba(0, 255, 0, 0.6)',  // Green color
    },
  },
  deleteIcon: {
    margin: theme.spacing(2), // Remove margin
    // padding: theme.spacing(1), // Remove padding
    fontSize: '30px',  // Adjust the size as needed
    color: 'rgba(200, 0, 0, 1)',  // Darker green on hover
    cursor: 'pointer',
    '&:hover': {
      color: 'rgba(255, 0, 0, 0.6)',  // Green color
    },
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    padding: theme.spacing(0),
  },
  
}));

const CollectionManager = () => {
  const classes = useStyles();
  const { myLists, setMyLists, list, setList, dictionary, sport } = useContext(DashboardContext);

  const [activeCollection, setActiveCollection] = useState(null);
  const [activeList, setActiveList] = useState(null);
  
  useEffect(() => {
    if (myLists?.length && sport) {
      const foundCollection = myLists.find(list => list.sport === sport);
      if (foundCollection) {
        setActiveCollection(foundCollection);
        setActiveList(foundCollection.contents[list] || null);  // Ensure the list is valid
      }
    }
  }, [sport, myLists, list]);
  
  const handleAddList = () => {
    activeCollection.contents.push({
      listname: 'New List',
      sport: sport,
      stats: [
      ],
      description: 'Optional Description',
    },)
    setList(activeCollection.contents.length - 1)
    setMyLists((prev) => {
      const newLists = [...prev]
      let index = newLists.findIndex(list => list.sport === sport)
      newLists[index].contents = activeCollection.contents
      return newLists;
    });
  }
  
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const newItems = [...activeList.stats];
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);
    setMyLists((prev) => {
      const newLists = [...prev]
      let index = newLists.findIndex(list => list.sport === sport)
      newLists[index].contents[list].stats = newItems;
      return newLists;
    });
  };

  const handleDeleteList = () => {
    if (activeCollection.contents.length <=1) {
      alert('You must have at least one list in a collection')
      return
    }
    const newItems = activeCollection.contents.filter((_, i) => i !== list);
    setMyLists((prev) => {
      const newLists = [...prev];
      let index = newLists.findIndex(list => list.sport === sport)
      newLists[index].contents = newItems;
      setList(0)
      return newLists;
    });
  };
  const handleDelete = (index) => {
    const newItems = activeList.stats.filter((_, i) => i !== index);
    setMyLists((prev) => {
      const newLists = [...prev];
      let index = newLists.findIndex(list => list.sport === sport)
      newLists[index].contents[list].stats = newItems;
      return newLists;
    });
  };
  
  if (!sport) {
    return (
      <Box component={Paper} className={classes.componentContainer}>
        <Typography variant='h6' align='center'>
          Select a League
        </Typography>
      </Box>
    );
  }
  
  if (!activeCollection || !activeList) {
    return (
      <Box component={Paper} className={classes.componentContainer}>
        <Typography variant='h6' align='center'>
          No data available for the selected sport.
        </Typography>
      </Box>
    );
  } 
  else
   {
    return (
      <Box component={Paper} className={classes.componentContainer}>
        <Box p={0.5}>
          
          <Select
            value={activeList.listname || ''}
            fullWidth
            displayEmpty
            onMouseDown={(e) => e.stopPropagation()}
          >
            <MenuItem value="" disabled>
              Select a Collection
            </MenuItem>
            
            {activeCollection.contents.map((l, i) => (
              <MenuItem
                key={i}
                value={l.listname}
                onMouseDown={(e) => e.stopPropagation()}
                onClick={() => setList(i)}
              >
                {l.listname}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box className={classes.headerBox} p={1}>
        <Tooltip title='Add A New List' placement='top'>
          <AddCircleTwoToneIcon
            className={classes.addIcon}
            onMouseDown={(e) => e.stopPropagation()}
          onClick={handleAddList}
            />
        </Tooltip>
        <Tooltip title='Delete This List' placement='top'>
          <IconButton onMouseDown={(e) => e.stopPropagation()} onClick={() => handleDeleteList(list)}>
            <DeleteTwoToneIcon className={classes.deleteIcon}/>
          </IconButton>
        </Tooltip>
        {
          activeList && <EditableTypography listname={activeList.listname} description={activeList.description}/>
        }
        </Box>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="list">
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {activeList.stats.map((item, index) => {
                  if (!Array.isArray(item[0]) || item[0].length === 0) {
                    console.warn(`Unexpected structure at index ${index}: `, item);
                    return null;  //Skip this item or handle it as needed
                  }
                  let dataIndex = item[1];
                  let statName = item[0][item[0].length - 1];
                    
                  let parents = item[0].slice(0, item[0].length - 1);
                  let valueIWantToRetrieve = dictionary;

                  for (let i = 0; i < parents.length; i++) {
                    valueIWantToRetrieve = valueIWantToRetrieve?.find(parent => parent.name === parents[i]) || {};
                  }
                  
                  let stats = valueIWantToRetrieve?.contents?.find(child => child.statName === statName) || {};
                            
                  let breadCrumb = IndexReference(dataIndex);

                  return (
                    <Draggable key={index} draggableId={`${stats.display || 'stat'}-${index}`} index={index}>
                      {(provided) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classes.listItem}
                        >
                          <ListItemIcon
                            {...provided.dragHandleProps}
                            onMouseDown={(e) => e.stopPropagation()}
                            className={classes.listItemIcon}
                          >
                            <DragIndicator />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Breadcrumbs className={classes.breadcrumbs}>
                                <Typography
                                  style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  }}
                                  variant='body2'
                                >
                                  {breadCrumb.join(' / ')}
                                </Typography>
                              </Breadcrumbs>
                            }
                            secondary={
                              <Typography variant='h6' className={classes.secondaryText}>
                                {statName || 'Unknown Stat'}
                              </Typography>
                            }
                            className={classes.listItemText}
                          />
                           <Tooltip title='Delete Stat' placement='top'>

                          <IconButton className={classes.deleteIcon} onMouseDown={(e) => e.stopPropagation()} onClick={() => handleDelete(index)}>
                            <DeleteTwoToneIcon />
                          </IconButton>
                           </Tooltip>
                        </ListItem>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
       
       </Box>
    )
  }
};

export default CollectionManager;
