import React, { useContext, useEffect, useState } from 'react';
import {
  Table, TableBody, TableRow, TableCell, Typography, Box, Paper,
  RadioGroup, Radio, FormControl, FormControlLabel, FormLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardContext } from './v3Context.js';

// Function to calculate gradient color with 50% opacity
const getGradientColor = (odds, min, max) => {
  const percent = (odds - min) / (max - min);
  const red = Math.round(255 * (1 - percent));
  const green = Math.round(255 * percent);
  return `rgba(${red}, ${green}, 0, 0.5)`; // 50% opacity
};

// Function to reorder selected bookmakers to the front of the array
const reorderBookmakers = (bookmakers, selectedBookmakers, sort) => {
  const normalizedSelectedBookmakers = selectedBookmakers.map(name => name.toLowerCase());
  const selected = [];
  const unselected = [];

  bookmakers.forEach(bookmaker => {
    if (normalizedSelectedBookmakers.includes(bookmaker.bookmaker.toLowerCase())) {
      selected.push(bookmaker);
    } else {
      unselected.push(bookmaker);
    }
  });

  // Adjust sorting order based on `sort` parameter
  if (sort === 'price') {
    selected.sort((a, b) => b.odds.price - a.odds.price);
    unselected.sort((a, b) => b.odds.price - a.odds.price);
  } else if (sort === 'point') {
    selected.sort((a, b) => b.odds.point - a.odds.point);
    unselected.sort((a, b) => b.odds.point - a.odds.point);
  } else if (sort === 'overround') {
    selected.sort((a, b) => a.odds.overround - b.odds.overround);
    unselected.sort((a, b) => a.odds.overround - b.odds.overround);
  }

  return [...selected, ...unselected];
}


const useStyles = makeStyles((theme) => ({
  componentContainer: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    flexDirection: 'column',
    display: 'flex',
    minWidth: 300,
    overflow: 'auto',
    height: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '49%',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%'
    },
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1),
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  table: {
    width: '100%',
    tableLayout: 'auto',
    borderSpacing: '5px',
    borderCollapse: 'separate',
  },
  tableRow: {
    marginBottom: theme.spacing(1),
  },
  tableCell: {
    padding: theme.spacing(1),
    width: '10%',
    backgroundColor: theme.palette.background.default,
    borderRadius: '10px',
  },
  titleCell: {
    display: 'flex',
    flexDirection: 'row',
    border: `0px solid ${theme.palette.divider}`,
    padding: theme.spacing(1),
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      width: '20%',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  highlightedBorder: {
    border: `3px solid ${theme.palette.primary.main}`,
  },
  bookmakerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sortContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    
  },
}));

const LineShop = () => {
  const classes = useStyles();
  const { selectedBookmakers, game, fetchFromAPI, betType, getBookmakerLink, } = useContext(DashboardContext);
  const [visitorArray, setVisitorArray] = useState([]);
  const [homeArray, setHomeArray] = useState([]);
  const [visitorTeamName, setVisitorTeamName] = useState('');
  const [homeTeamName, setHomeTeamName] = useState('');
  const [visitorIcon, setVisitorLogo] = useState('');
  const [homeIcon, setHomeIcon] = useState('');
  const [error, setError] = useState(null);
  const [orderBy, setOrderBy] = useState('price');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (game) {
          let lineShopData = await fetchFromAPI('v3/lineShop', 'POST', { game: game.oddsAPIId, betType });
          
          if (lineShopData && (betType === 'Spreads' || betType === 'MoneyLine')) {
            setVisitorArray(lineShopData[game.visitorTeam.teamName] || []);
            setVisitorTeamName(game.visitorTeam.teamName || 'Visitor');
            setHomeArray(lineShopData[game.homeTeam.teamName] || []);
            setHomeTeamName(game.homeTeam.teamName || 'Home');
            setVisitorLogo(<img src={game.visitorTeam.logoURL} alt="" width="50" height="50" />);
            setHomeIcon(<img src={game.homeTeam.logoURL} alt="" width="50" height="50" />);
          } else if (lineShopData && betType === 'Totals') {
            setVisitorArray(lineShopData['Over'] || []);
            setVisitorTeamName('Over');
            setHomeArray(lineShopData['Under'] || []);
            setHomeTeamName('Under');
          }
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load line shop data. Please try again later.');
      }
    };
    fetchData();
  }, [game, betType]);

  // Calculate the min and max odds to determine the gradient range
  const visitorMinOdds = visitorArray.length ? Math.min(...visitorArray.map(b => b.odds.price)) : 0;
  const visitorMaxOdds = visitorArray.length ? Math.max(...visitorArray.map(b => b.odds.price)) : 0;
  const visitorMaxPoint = visitorArray.length ? Math.max(...visitorArray.map(b => b.odds.point)) : 0;
  const visitorMinPoint = visitorArray.length ? Math.min(...visitorArray.map(b => b.odds.point)) : 0;
  const visitorMaxOverround = visitorArray.length ? Math.max(...visitorArray.map(b => b.odds.overround)) : 0;
  const visitorMinOverround = visitorArray.length ? Math.min(...visitorArray.map(b => b.odds.overround)) : 0;


  const homeMinOdds = homeArray.length ? Math.min(...homeArray.map(b => b.odds.price)) : 0;
  const homeMaxOdds = homeArray.length ? Math.max(...homeArray.map(b => b.odds.price)) : 0;
  const homeMaxPoint = homeArray.length ? Math.max(...homeArray.map(b => b.odds.point)) : 0;
  const homeMinPoint = homeArray.length ? Math.min(...homeArray.map(b => b.odds.point)) : 0;
  const homeMaxOverround = homeArray.length ? Math.max(...homeArray.map(b => b.odds.overround)) : 0;
  const homeMinOverround = homeArray.length ? Math.min(...homeArray.map(b => b.odds.overround)) : 0;

  // Reorder the arrays based on selectedBookmakers
  const reorderedVisitorArray = reorderBookmakers(visitorArray, selectedBookmakers, orderBy);
  const reorderedHomeArray = reorderBookmakers(homeArray, selectedBookmakers, orderBy);

  if (!game) {
    return <></>;
  }

  if (error) {
    return <Typography variant="body1" color="error">{error}</Typography>;
  }

  function SortSelect() {
    const classes = useStyles(); // Ensure styles are accessible in this function
  
    return (
      <FormControl component="fieldset">
        <Box className={classes.sortContainer}>
          <Typography variant="subtitle1" style={{ marginRight: '16px' }}>
            Sort By:
          </Typography>
          <RadioGroup
            row
            aria-label="sortOrder"
            name="sortOrder"
            value={orderBy} // Control RadioGroup with orderBy state
            onChange={(e) => setOrderBy(e.target.value)}
          >
            <FormControlLabel value="price" control={<Radio color="primary" />} label="Odds" />
            <FormControlLabel value="point" control={<Radio color="primary" />} label="Point" />
            {/* <FormControlLabel value="overround" control={<Radio color="primary" />} label="Vig" /> */}
          </RadioGroup>
        </Box>
      </FormControl>
    );
  }

  return (
    <Paper className={classes.componentContainer}>
      <Box className={classes.selectContainer}>
      <Typography variant="h6" align="left" style={{ fontStyle: 'italic' }}>
          {game.home.league} U.S. Bookmakers Line Shop
        </Typography>
        <SortSelect />
      </Box>
      <Table className={classes.table}>
        <TableBody>
          {visitorArray.length > 0 && (
            <TableRow className={classes.tableRow}>
              <TableCell align="left" className={classes.titleCell}>
                {betType === 'Spreads' || betType === 'MoneyLine' ? (
                  <span className={classes.logo}>{visitorIcon}</span>
                ) : (
                  <Typography variant="subtitle1">Over</Typography>
                )}
              </TableCell>
              {reorderedVisitorArray.map((bookmaker, index) => {
                let backgroundColor
                switch (orderBy) {
                  case 'price':
                    backgroundColor = getGradientColor(bookmaker.odds.price, visitorMinOdds, visitorMaxOdds);
                    break;
                  case 'point':
                    backgroundColor = getGradientColor(bookmaker.odds.point, visitorMinPoint, visitorMaxPoint);
                    break;
                  case 'overround':
                    backgroundColor = getGradientColor(bookmaker.odds.overround, visitorMinOverround, visitorMaxOverround);
                    break;
                  default:
                    backgroundColor = getGradientColor(bookmaker.odds.price, visitorMinOdds, visitorMaxOdds);
                }
                const isSelected = selectedBookmakers.includes(bookmaker.bookmaker);

                return (
                  <TableCell
                    key={index}
                    align="center"
                    className={`${classes.tableCell} ${isSelected ? classes.highlightedBorder : ''}`}
                    style={{ backgroundColor }}
                  >
                    <Box className={classes.bookmakerContainer}>
                      {(betType === 'Spreads') ? (
                        <>
                          <Typography variant="caption">{getBookmakerLink(bookmaker.bookmaker)[0]}</Typography>
                          <Typography variant="subtitle1">{bookmaker.odds.price > 0 ? `+${bookmaker.odds.price}` : bookmaker.odds.price}</Typography>
                          <Typography variant="subtitle2">{bookmaker.odds.point}</Typography>
                        </>
                      ) : (betType === 'Totals') ? (
                        <>
                          <Typography variant="caption">{getBookmakerLink(bookmaker.bookmaker)[0]}</Typography>
                          <Typography variant="subtitle1">{bookmaker.odds.price > 0 ? `+${bookmaker.odds.price}` : bookmaker.odds.price}</Typography>
                          <Typography variant="subtitle2">{bookmaker.odds.point}</Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant="caption">{getBookmakerLink(bookmaker.bookmaker)[0]}</Typography>
                          <Typography variant="subtitle1">{bookmaker.odds.price}</Typography>
                        </>
                      )}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          )}

          {homeArray.length > 0 && (
            <TableRow className={classes.tableRow}>
              <TableCell align="left" className={classes.titleCell}>
                {betType === 'Spreads' || betType === 'MoneyLine' ? (
                  <span className={classes.logo}>{homeIcon}</span>
                ) : (
                  <Typography variant="subtitle1">Under</Typography>
                )}
              </TableCell>
              {reorderedHomeArray.map((bookmaker, index) => {
                let backgroundColor
                switch (orderBy) {
                  case 'price':
                    backgroundColor = getGradientColor(bookmaker.odds.price, homeMinOdds, homeMaxOdds);
                    break;
                  case 'point':
                    backgroundColor = getGradientColor(bookmaker.odds.point, homeMinPoint, homeMaxPoint);
                    break;
                  case 'overround':
                    backgroundColor = getGradientColor(bookmaker.odds.overround, homeMinOverround, homeMaxOverround);
                    break;
                  default:
                    backgroundColor = getGradientColor(bookmaker.odds.price, homeMinOdds, homeMaxOdds);
                }
                const isSelected = selectedBookmakers.includes(bookmaker.bookmaker);

                return (
                  <TableCell
                    key={index}
                    align="center"
                    className={`${classes.tableCell} ${isSelected ? classes.highlightedBorder : ''}`}
                    style={{ backgroundColor }}
                  >
                    <Box className={classes.bookmakerContainer}>
                       {(betType === 'Spreads') ? (
                        <>
                          <Typography variant="subtitle2">{bookmaker.odds.point}</Typography>
                          <Typography variant="subtitle1">{bookmaker.odds.price > 0 ? `+${bookmaker.odds.price}` : bookmaker.odds.price}</Typography>
                          <Typography variant="caption">{getBookmakerLink(bookmaker.bookmaker)[0]}</Typography>
                        </>
                      ) : (betType === 'Totals') ? (
                        <>
                          <Typography variant="subtitle2">{bookmaker.odds.point}</Typography>
                          <Typography variant="subtitle1">{bookmaker.odds.price > 0 ? `+${bookmaker.odds.price}` : bookmaker.odds.price}</Typography>
                          <Typography variant="caption">{getBookmakerLink(bookmaker.bookmaker)[0]}</Typography>
                        </>
                      ) : (
                        <>
                          <Typography variant="subtitle1">{bookmaker.odds.price}</Typography>
                          <Typography variant="caption">{getBookmakerLink(bookmaker.bookmaker)[0]}</Typography>
                        </>
                      )}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default React.memo(LineShop);
