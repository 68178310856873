import React, { useState, useEffect, useContext } from 'react';
import { DashboardContext } from './v3Context.js';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Paper, useMediaQuery } from '@material-ui/core';
import TimelineIcon from '@material-ui/icons/Timeline';

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    justifyContent: 'top',
    overflow: 'auto',
    [theme.breakpoints.up('xl')]: {
      // minWidth: 450,   // Example min width for extra-large screens
      height: 400,
      width: '31%',    // Adjusted width for extra-large screens
    },
    [theme.breakpoints.down('lg')]: {
      width: '50%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
  },
  chartWrapper: {
    width: '90%',
    height: (props) => props.chartHeight || 300, // Ensure a fallback height is provided
    position: 'relative',
  },
  emptyChartText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
}));

const StatLineChart = () => {
  const theme = useTheme(); // To access theme breakpoints
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg')); // Large screens
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md')); // Medium screens
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Small screens

  // Determine chart height based on screen size
  const chartHeight = isSmallScreen ? 300 : isMediumScreen ? 250 : isLargeScreen ? 350 : 300;
  const classes = useStyles({ chartHeight });

  const { chartDataList, fetchFromAPI, game, activeLeague, view } = useContext(DashboardContext);
  const [chartData, setChartData] = useState([]);
  const [activeKeys, setActiveKeys] = useState([]);
  const [debouncedChartData, setDebouncedChartData] = useState([]);
  const [visitorColors, setVisitorColors] = useState([]);
  const [homeColors, setHomeColors] = useState([]);
  
  const renameKeys = (obj, game) => {
    const newObj = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const splitKey = key.split('-');
            
            // Check if the key starts with a number and matches the team ID
            if (splitKey.length > 1 && parseInt(splitKey[0]) === game.homeTeam.id) {
                setHomeColors([game.homeTeam.primaryColor, game.homeTeam.secondaryColor]);
                // Replace the key with the team name
                const newKey = `${game.homeTeam.teamName} - ${splitKey.slice(1).join(' - ')}`;
                newObj[newKey] = obj[key];
            } else if (splitKey.length > 1 && parseInt(splitKey[0]) === game.visitorTeam.id) {
                setVisitorColors([game.visitorTeam.primaryColor, game.visitorTeam.secondaryColor]);
                // Replace the key with the team name
                const newKey = `${game.visitorTeam.teamName} - ${splitKey.slice(1).join(' - ')}`;
                newObj[newKey] = obj[key];
            } else {
                // Keep the original key if it doesn't match
                newObj[key] = obj[key];
            }
        }
    }

    return newObj;
  };

  // Debounce effect
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedChartData(chartData);
    }, 100);
  
    return () => clearTimeout(timer);
  }, [chartData]);

  useEffect(() => {
    if (!game || chartDataList.length === 0) {
      setChartData([]);
      return;
    }
    const fetchChartData = async () => {
      const endpoint = 'v3/chartData';
      const method = 'POST';
      const data = {
        view: view,
        league: activeLeague,
        visitor: game.visitor,
        home: game.home,
        data: chartDataList,
        gamedate: game.gamedate,
      };

      try {
        let response = await fetchFromAPI(endpoint, method, data);
        
        // Ensure response is an array and process each item
        if (Array.isArray(response) && response.length > 0) {
          const renamedData = response.map((item, index) => {
            // Set 'Game' key to the index + 1
            item.Game = index + 1;
            return renameKeys(item, game);
          });
          
          // Set chart data safely
          setChartData(renamedData);

          // Set active keys based on the renamed data
          if (renamedData.length > 0) {
            setActiveKeys(Object.keys(renamedData[0]).filter((key) => key !== 'Game'));
          } else {
            setActiveKeys([]); // Empty if no valid keys are present
          }
        } else {
          setChartData([]);
          setActiveKeys([]);
        }
        
      } catch (error) {
        console.error('Error fetching chart data:', error);
        setChartData([]);
        setActiveKeys([]);
      }
    };

    fetchChartData();
  }, [chartDataList, game, activeLeague, fetchFromAPI, view]);


  const keys = chartData.length > 0 ? Object.keys(chartData[0]).filter((key) => key !== 'Game') : [];

  if (game) {
    return (
      <Paper elevation={2} className={classes.componentContainer}>
        <Typography variant="h6" align="left" style={{ fontStyle: 'italic' }}>
          Stat Chart
        </Typography>
        <div className={classes.chartWrapper}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={debouncedChartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Game" />
              <YAxis />
              <Tooltip />
              {keys.map((key, index) => {
                const isHomeTeam = key.includes(game.homeTeam.teamName);
                const colorArray = isHomeTeam ? homeColors : visitorColors;
                const primaryColor = colorArray[0] || '#000';  // Fallback to black if no primary color is set
                const secondaryColor = colorArray[1] || '#ccc'; // Fallback to light gray if no secondary color is set

                return (
                  activeKeys.includes(key) && (
                    <React.Fragment key={key}>
                      {/* Outer stroke with secondary color */}
                      <Line
                        type="bump"
                        dataKey={key}
                        stroke={primaryColor}
                        strokeWidth={8} // Make it slightly thicker for the shadow effect
                        dot={false}
                        activeDot={false}
                        isAnimationActive={false} // Keep animations off for the outer line to prevent overlap
                        />
                      
                      {/* Inner stroke with primary color */}
                      <Line
                        type="bump"
                        dataKey={key}
                        tooltipType="none" // Exclude this line from the tooltip
                        stroke={secondaryColor}
                        strokeWidth={3} // Thinner than the outer line
                        dot={false}
                        // activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }}
                        isAnimationActive={false} // Keep animations off for the outer line to prevent overlap
                      />
                    </React.Fragment>
                  )
                );
              })}
            </LineChart>
          </ResponsiveContainer>
          {debouncedChartData.length === 0 &&
          <>
            <Typography className={classes.emptyChartText}>
              Select Data to Chart from the Matchup Report
            </Typography>
            <TimelineIcon />
            </>
            
          }
        </div>
      </Paper>
    );
  } else {
    return <></>;
  }
};

export default React.memo(StatLineChart);
