import React, { useState, useContext, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { MenuItem, Box, Button, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Tooltip, LinearProgress } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useAuth } from "../util/auth.js";
import { DashboardContext } from "./v3Context.js";
import { makeStyles } from '@material-ui/core/styles';
import DataRow from './DataRow.jsx';
import BarChartIcon from '@material-ui/icons/BarChart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import MenuBookTwoToneIcon from '@material-ui/icons/MenuBookTwoTone';

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    overflow: 'auto',
    [theme.breakpoints.up('xl')]: {
      minWidth: 450,   // Example min width for extra-large screens
      width: '31%',
    },
    [theme.breakpoints.down('lg')]: {
      height: '100%',
      maxHeight: 500,
      minWidth: 300,
      width: '38%', // 40% width for large screens
    },
    [theme.breakpoints.down('md')]: {
      // maxHeight: 500,
      // height: 500,
      width: '48%', // 60% width for medium screens
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%', // 100% width for small screens like tablets and phones
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      // height: '70vh',
    },
  },
  tableContainer: {
    width: '100%',
    height: '79%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  strikeOutText: {
    textDecoration: 'line-through',
    color: theme.palette.text.disabled,
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },
  headerCell: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 5, 0, 0),
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    // margin: theme.spacing(.5), // Add space below icons
    marginRight: theme.spacing(4), // Add space to the right of icons
  },
  icon: {
    margin: theme.spacing(1), // Add space below icons
    marginLeft: theme.spacing(1), // Add space between icons
  },
  teamNames: {
    margin: theme.spacing(1), // Add space below icons
    marginRight: theme.spacing(0), // Add space below team names
  },
  chartLabel: {
    margin: theme.spacing(0), // Add space below icons
    // alignSelf: 'flex-end', // Align 'Chart' text to the right
  },
  comparisonTableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  cell: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  dragHandle: {
    cursor: 'grab',
    // padding: theme.spacing(1),
  },
  dragHandleCell: {
    width: 'auto',
    border: 'none',
    // flexShrink: 0,
    padding: theme.spacing(1),
  },
  dataRowCell: {
    flex: 1,
    width: '100%',
    padding: theme.spacing(0),
    border: 'none',
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    fontSize: 14,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  selectedView: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main, // Selected background color
    color: theme.palette.primary.contrastText, // Icon color for selected state
    borderRadius: 8, // Rounded square buttons
    padding: theme.spacing(1),
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${theme.palette.primary.dark}`, // Add a border to selected
    '&:hover': {
      backgroundColor: theme.palette.primary.dark, // Darker shade on hover
    },
  },
  unselectedView: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper, // Unselected background color
    color: theme.palette.text.secondary, // Icon color for unselected state
    borderRadius: 8, // Rounded square buttons
    padding: theme.spacing(1),
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px solid ${theme.palette.divider}`, // Light border for unselected
    '&:hover': {
      backgroundColor: theme.palette.action.hover, // Hover effect for unselected
    },
  },
  select: {
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      width: '40%',
      flexDirection: 'column', // Stack title content vertically on small screens
      alignItems: 'center',
      margin: theme.spacing(1),
    },
    width: '50%',
    // backgroundColor: '#fff',
    // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.4)',
    // margin: theme.spacing(1),
    // padding: theme.spacing(2),
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  dictionaryIcon: {
    cursor: 'pointer',
    color: theme.palette.secondary,
    
  },
  dictionaryButton: {
    height: '100%',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      height: '55px',
      // width: '20%',
    },
    color: theme.palette.secondary,
    borderRadius: '5px',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.primary.main,  // Change background on hover
    },

  }, 

  dictionaryContainer: {

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
   
    
    
    padding: theme.spacing(0),
    // backgroundColor: theme.palette.primary.light,  // Attention-grabbing background
    borderRadius: theme.shape.borderRadius,  // Rounded corners
    marginLeft: theme.spacing(0),  // Space between the select and the icon
    cursor: 'pointer',
    
  },
}));

const ComparisonTable = () => {
  const classes = useStyles();
  const { myLists, setMyLists, view, setView, list, setList, sport, game, gameData, setGameData, dictionary, isDictionaryOpen, setDictionaryOpen } = useContext(DashboardContext);
  const [tableData, setTableData] = useState([]);
  const auth = useAuth();
  

  useEffect(() => {
    if (gameData) {
      setTableData(gameData);
    }
  }, [gameData]);

  function findStatByPath(path) {
    let result = dictionary;
    for (let i = 0; i < path.length; i++) {
      const element = path[i];
      try {
        let folder = result.find((item) => item.name === element);
        if (folder) {
          result = folder.contents;
        } else {
          let stat = result.find((item) => item.statName === element);
          result = stat;
        }
      } catch (error) {
        console.log('Error findStatByPath', error);
        return null; // Return null or an appropriate fallback
      }
    }
    return result;
  }

  function viewHandler(e) {
    setView(e.currentTarget.id)
  }

 const DisplayValue = (value) => (Number(value) === value && value % 1 !== 0) ? value.toFixed(2) : value;
  

  let listOptions = myLists.find((s) => s.sport === sport)?.contents || [];
  
  if (game) {
    const vteam = game.visitorTeam
    const hteam = game.homeTeam;
    const homeIcon = <img src={hteam.logoURL} alt="" width="50" height="50" />;
    const visitorIcon = <img src={vteam.logoURL} alt="" width="50" height="50" />;
    return (
      <Paper elevation={2} className={classes.componentContainer}>
         <Typography variant="h6" align="left" style={{ fontStyle: 'italic' }}>
          Matchup Report
        </Typography>
        
        <Box className={classes.dictionaryContainer}>
        <Select
         className={classes.select}
          value={list}
          onChange={(e) => setList(e.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="" disabled>
            Select a List
          </MenuItem>
          {listOptions.map((item, index) => (
            <MenuItem key={index} value={index}>
              {item.listname}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          color="primary"
          className={classes.dictionaryButton}
          onClick={() => {
            setDictionaryOpen(!isDictionaryOpen);
          }}
        >
          <MenuBookTwoToneIcon
            className={classes.dictionaryIcon}
          />
          <Typography variant="body1">stat Picker</Typography>
        </Button>
        </Box>
          <TableContainer component={Paper} className={classes.tableContainer}>
          <Table aria-label="comparison table">
            <TableHead>
              <TableRow className={classes.stickyHeader}>
                <TableCell className={classes.headerCell}>
                  <div className={classes.iconContainer}>
                    {/* Total, Accumulated */}
                    <Tooltip  classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} title={
                      <React.Fragment>
                        <Typography color="inherit" variant='h6'>Cumulative Total</Typography>
                        <Typography color="inherit" variant='body2'>{`This view is the total, accumulated value for the time frame.`}</Typography>
  
                      </React.Fragment>
                        } placement="bottom">
                      <EqualizerIcon alt="icon" className={parseInt(view) === 0 ? classes.selectedView : classes.unselectedView} onMouseDown={(e) => e.stopPropagation()} onClick={viewHandler} id={0} />
                    </Tooltip>
                    {/* Average Per Game */}
                    <Tooltip classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} title={
                      <React.Fragment>
                        <Typography color="inherit" variant='h6'>Average Per Game</Typography>
                        <Typography color="inherit" variant='body2'>{`This view is the average per game for the time frame.`}</Typography>
                      </React.Fragment>
                      } placement="bottom">
                    <TrendingUpIcon alt="icon" className={parseInt(view) === 1 ? classes.selectedView : classes.unselectedView} onMouseDown={(e) => e.stopPropagation()} onClick={viewHandler} id={1}/>
                    </Tooltip>
                    {/* League Normalized Values */}
                    <Tooltip classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} title={
                      <React.Fragment>
                        <Typography color="inherit" variant='h6'>League Normalized</Typography>
                        <Typography color="inherit" variant='body2'>{`In this view, the data for the whole league is remapped, or squished, to values between 0 and 1, with 0 being the worst team and 1 being the best team`}</Typography>
                      </React.Fragment>
                      } placement="top">
  
                    <MultilineChartIcon alt="icon" className={parseInt(view) === 2 ? classes.selectedView : classes.unselectedView} onMouseDown={(e) => e.stopPropagation()} onClick={viewHandler} id={2}/>
                    </Tooltip>
                    {/* Z-Scores */}
                    <Tooltip classes={{ tooltip: classes.tooltip, arrow: classes.arrow }} title={
                      <React.Fragment>
                        <Typography color="inherit" variant='h6'>Z-Scores</Typography>
                        <Typography color="inherit" variant='body2'>{`Z Scores are a measure that tells you how far a team's performance is from the average, for the time frame, in terms of standard deviations. It helps normalize performance across different games or players. A Z-score of 1 means the player is 1 standard deviation above the average. Positive Z-scores indicate above-average performance, while negative Z-scores indicate below-average performance. A Z-score close to 0 suggests performance near the average.`}</Typography>
                      </React.Fragment>
                      } placement="top">
                    <BarChartIcon alt="icon" className={parseInt(view) === 3 ? classes.selectedView : classes.unselectedView} onMouseDown={(e) => e.stopPropagation()} onClick={viewHandler} id={3}/>
                    </Tooltip>
                  </div>
                  {/* <Typography className={classes.teamNames} variant="subtitle1" align="right"> */}
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <span className={classes.icon}>{visitorIcon}</span>
                      </div>
                    {/* {game?.visitor?.teamName || 'Visitor'} */}
                  {/* </Typography> */}
                  <Typography className={classes.teamNames} variant="subtitle1" align="right">
                    -
                  </Typography>
                  {/* <Typography className={classes.teamNames} variant="subtitle1" align="right"> */}
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <span className={classes.icon}>{homeIcon}</span>
                      </div>
                    {/* {game?.home?.teamName || 'Home'} */}
                  {/* </Typography> */}
                </TableCell>
              </TableRow>
            </TableHead>
            {gameData ? (
              <TableBody>
              {tableData.map((row, index) => {
                if (!gameData[index] || !gameData[index].ref) {
                  console.warn(`Invalid data at index ${index}: `, gameData[index]);
                  return null;
                }
                let entry = gameData[index];
                let stat = findStatByPath(gameData[index].ref) || {}; // Provide a fallback
                console.log('stat', stat, 'entry', entry, 'index', index)
                return (
                  <TableRow key={index} className={classes.comparisonTableRow}>
                    <DataRow
                      view={view}
                      title={stat.statName}
                      visitorValue={DisplayValue(entry.VisitorValue)}
                      homeValue={DisplayValue(entry.HomeValue)}
                      reference={entry}
                      subtitle={stat.breadCrumb?.join(' / ') || 'Unknown Path'}
                      splitIndex={entry.index}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
              ) : ( 
               <></>
              )
            }
          </Table>
        </TableContainer>
      </Paper>
    );

  } else {
    return (
     <></>
    );
  }
};

export default ComparisonTable