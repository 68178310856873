import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DashboardContext } from './v3Context.js';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.paper,
    // border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  header: {
    // backgroundColor: theme.palette.grey[200],
    // padding: theme.spacing(1, 2),
  },
  tableHead: {
    // backgroundColor: theme.palette.grey[100],
  },
  iconCell: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  switchBase: {
    '&$checked': {
      color: '#4caf50', // Green color when checked
    },
    '&$checked + $track': {
      backgroundColor: '#4caf50', // Green track when checked
    },
  },
  checked: {},
  track: {},
  
}));

const SelectorModule = ({ file }) => {
  const classes = useStyles();
  const { addStat, list, myLists, sport } = useContext(DashboardContext);

  const doesReferenceMatch = (reference) => {
    let index = myLists.findIndex((s) => s.sport === sport )
    return myLists[index].contents[list].stats.some((stat) => {
      return stat[0] === reference[0] && stat[1] === reference[1];
    });
  };

  const initializeRows = () => {
    return [
      { 
        name: 'Total',
        longSwitch: {
          disabled: false,
          checked: doesReferenceMatch([file.ref, 0]),
          index: 0
        },
        shortSwitch: {
          disabled: false,
          checked: doesReferenceMatch([file.ref, 4]),
          index: 4
        }
      },
      {
        name: 'as Home / Away',
        longSwitch: {
          disabled: false,
          checked: doesReferenceMatch([file.ref, 1]),
          index: 1
        },
        shortSwitch: {
          disabled: false,
          checked: doesReferenceMatch([file.ref, 5]),
          index: 5
        }
      },
      {
        name: 'as Favorite / Underdog',
        longSwitch: {
          disabled: false,
          checked: doesReferenceMatch([file.ref, 2]),
          index: 2
        },
        shortSwitch: {
          disabled: false,
          checked: doesReferenceMatch([file.ref, 6]),
          index: 6
        }
      },
      {
        name: 'In Conference / Not',
        longSwitch: {
          disabled: false,
          checked: doesReferenceMatch([file.ref, 3]),
          index: 3
        },
        shortSwitch: {
          disabled: false,
          checked: doesReferenceMatch([file.ref, 7]),
          index: 7
        }
      },
    ];
  };

  const [rows, setRows] = useState(initializeRows);

  useEffect(() => {
    setRows(initializeRows());
  }, [list, myLists, file]);

  const toggleIndex = (event) => {
    const index = parseInt(event.currentTarget.getAttribute('index'));
    const time = event.currentTarget.getAttribute('time');
  
    addStat(file.ref, index);
  
    setRows((prevRows) =>
      prevRows.map((row) => {
        if (row[time].index === index) {
          const isPresent = doesReferenceMatch([file.ref, index]);
          console.log('Present', isPresent)
          return {
            ...row,
            [time]: {
              ...row[time],
              checked: isPresent,
            },
          };
        }
        return row;
      })
    );
  };
  return (
    <Box className={classes.root}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">Full Season</TableCell>
              <TableCell align="center">Last 3 Games</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">
                  <Switch classes={{
                      switchBase: classes.switchBase,
                      checked: classes.checked,
                      track: classes.track,
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={toggleIndex}
                    disabled={row.longSwitch.disabled}
                    time="longSwitch"
                    index={row.longSwitch.index}
                    checked={row.longSwitch.checked}
                    />
                </TableCell>
                <TableCell align="center">
                  <Switch classes={{
                      switchBase: classes.switchBase,
                      checked: classes.checked,
                      track: classes.track,
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={toggleIndex}
                    disabled={row.shortSwitch.disabled}
                    time="shortSwitch"
                    index={row.shortSwitch.index}
                    checked={row.shortSwitch.checked}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SelectorModule;
