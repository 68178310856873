import React, { useContext, useState, useEffect } from 'react';
import { DashboardContext } from './v3Context.js';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab, Typography, CircularProgress } from '@material-ui/core';
import { format, isToday, addDays, isSameDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    alignContent: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    overflow: 'auto',
    [theme.breakpoints.up('xl')]: {
      maxHeight: 500,
      minWidth: 400,   // Example min width for extra-large screens
      width: '48%',    // Adjusted width for extra-large screens
    },
    [theme.breakpoints.down('lg')]: {
      maxHeight: 500,
      minWidth: 500,
      width: '4*%',
    },
    [theme.breakpoints.down('md')]: {
      maxHeight: 500,
      width: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 800,
      width: '100%',
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
  },
  tableContainer: {
    width: '100%',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      maxHeight: 500,
    },
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundImage: `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
    color: theme.palette.common.white,
    zIndex: 1,
  },
  eventRow: {
    cursor: 'pointer',
    transition: 'transform 0.2s ease-in-out, background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      transform: 'scale(1.02)',
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.action.focus,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    boxShadow: '0px 2px 5px rgba(0,0,0,0.2)',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0.5),
    borderRadius: '50%',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5),
  },
  score: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
  cell: {
    textAlign: 'center',
    padding: theme.spacing(1),
    fontSize: '0.95rem',
  },
  hcell: {
    textAlign: 'right',
    fontSize: '0.95rem',
    padding: theme.spacing(1),
  },
  vcell: {
    textAlign: 'left',
    fontSize: '0.95rem',
    padding: theme.spacing(1),
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    '& .MuiTab-root': {
      minWidth: '50%',
    },
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));


const GameSchedule = () => {
  const classes = useStyles();
  
  const { fetchFromAPI, activeLeague, game, setGame, setGameData } = useContext(DashboardContext);
  const [group, setGroup] = useState('Upcoming');
  const [gameList, setGameList] = useState(null);
  useEffect(() => {
    if (activeLeague) {
      setGame(null);
      setGameData(null);
      setGameList(null);
      fetchFromAPI('v3/games', 'POST', { league: activeLeague }).then((response) => {
        setGameList(response);
      });
    }
  }, [activeLeague]);

  const handleGroupChange = (event, newValue) => {
    event.stopPropagation();
    setGroup(newValue);
  };

  const formatEventTime = (eventTime) => {
    const eventDate = new Date(eventTime);
    const timeZone = 'America/New_York';
    const zonedDate = toZonedTime(eventDate, timeZone);
    const today = new Date();
    const tomorrow = addDays(today, 1);
    if (isToday(zonedDate)) {
      return `Today, ${format(zonedDate, 'hh:mm a')} EST`;
    } else if (isSameDay(zonedDate, tomorrow)) {
      return `Tomorrow, ${format(zonedDate, 'hh:mm a')} EST`;
    } else {
      return `${format(zonedDate, 'MMMM d, h:mm')} pm`;
    }
  };

  const handleItemClick = (item) => {
    console.log(item)
    setGame(item);
  };
  if (!gameList) {
    return <CircularProgress />;
  }
  
  return (
    <Paper elevation={2} className={classes.componentContainer}>
      <Typography variant="h6" align="left" style={{ fontStyle: 'italic' }}>
        Game Schedule - {activeLeague}
        </Typography>
      {/* <Tabs
        value={group}
        onMouseDown={(e) => e.stopPropagation()}
        onChange={handleGroupChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        className={classes.tabs}
      > */}
        {/* <Tab label={`Upcoming (${gameList['Upcoming'].length || 0})`} value="Upcoming" /> */}
        {/* <Tab label={`Playing Now (${gameList['Ongoing'].length || 0})`} value="Ongoing" /> */}
      {/* </Tabs> */}
      <TableContainer component={Paper} className={classes.tableContainer}>
  <Table stickyHeader>
    <TableHead>
      <TableRow className={classes.stickyHeader}>
        <TableCell className={classes.stickyHeader}>Time</TableCell>
        <TableCell colSpan={2} className={classes.stickyHeader}>Visitor</TableCell>
        <TableCell colSpan={2} className={classes.stickyHeader}>Home</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {!activeLeague ? (
        <TableRow>
          <TableCell colSpan={6}>Select a league to view games</TableCell>
        </TableRow>
      ) : (
        gameList[group].map((g, index) => {
            const selected = game && (game.oddsAPIId ? game.oddsAPIId === g.oddsAPIId : g.id === game.id);
            const vteam = g.visitorTeam;
            const hteam = g.homeTeam;
            const gameTime = g.eventTime;
            const homeIcon = <img src={hteam.logoURL} alt="" width="50" height="50" />;
            const visitorIcon = <img src={vteam.logoURL} alt="" width="50" height="50" />;

                return (
                  <TableRow
                    key={index}
                    className={`${classes.eventRow} ${selected ? classes.selectedRow : ''}`}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(event) => handleItemClick(g)}
                  >
                    <TableCell className={classes.cell}>{formatEventTime(gameTime)}</TableCell>
                    <TableCell colSpan={1} className={classes.hcell}>{vteam.teamName}</TableCell>
                    <TableCell className={classes.cell}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span className={classes.logo}>{visitorIcon}</span>
                        <Typography className={classes.score} variant="subtitle1">{g.scoreVis}</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.cell}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography className={classes.score} variant="subtitle1">{g.scoreHome}</Typography>
                        <span className={classes.logo}>{homeIcon}</span>
                      </div>
                    </TableCell>
                    <TableCell colSpan={3} className={classes.vcell}>{hteam.teamName}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default React.memo(GameSchedule);
