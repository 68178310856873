import React, { useState, useEffect, useContext, useRef } from 'react';
import { DashboardContext } from './v3Context.js';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
} from 'recharts';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CircularProgress, Typography, Paper } from '@material-ui/core';
import moment from 'moment';

function decompressOddsData(compressedData) {
  const decompressedData = [];
  
  for (let i = 0; i < compressedData.length - 1; i++) {
    let currentEntry = compressedData[i];
    let nextEntry = compressedData[i + 1];
    
    // Convert timestamps to Date objects for easier manipulation
    let currentTime = new Date(currentEntry.timestamp);
    let nextTime = new Date(nextEntry.timestamp);
    
    // Push the current entry to decompressedData
    decompressedData.push({ ...currentEntry });

    // Fill in each minute until the next entry
    while (currentTime < nextTime) {
      // Increment the time by 1 minute
      currentTime.setMinutes(currentTime.getMinutes() + 1);
      currentTime.setSeconds(0); // Set seconds to 0 for each added entry

      // Stop if the new time is >= the next entry's timestamp
      if (currentTime >= nextTime) break;
      
      // Add a copy of currentEntry with the updated timestamp
      decompressedData.push({
        ...currentEntry,
        timestamp: currentTime.toISOString(),
      });
    }
  }

  // Push the last entry in compressedData
  decompressedData.push({ ...compressedData[compressedData.length - 1] });

  return decompressedData;
}



const useStyles = makeStyles((theme) => ({
  componentContainer: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    width: '100%', // Full width by default
    overflow: 'auto',
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      width: '40%', // 40% width for large screens
    },
    [theme.breakpoints.down('md')]: {
      width: '100%', // 60% width for medium screens
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%', // 100% width for small screens like tablets and phones
      height: '100%',
    },
  },
  chartWrapper: {
    height: 200,  // Set explicit height for chart container
  },
}));

const OddsChart = () => {
  const classes = useStyles();
  const { fetchFromAPI, game, activeLeague, selectedBookmakers, betType } = useContext(DashboardContext);
  const [oddsData, setOddsData] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (!game || selectedBookmakers.length === 0) {
      setOddsData([]);
      return;
    }

    const fetchOddsData = async () => {
      const endpoint = 'v3/oddsData';
      const method = 'POST';
      const data = {
        league: activeLeague,
        game: game,
        betType: betType,
        bookmakers: selectedBookmakers,
      };
      try {
        setLoading(true);
        const response = await fetchFromAPI(endpoint, method, data);
        setOddsData(decompressOddsData(response))
      } catch (error) {
        console.error("Error fetching chart data:", error);
        setOddsData([]);
      } finally {
        setLoading(false); // Stop loading once fetching completes
      }
    };

    if (isInitialMount.current) {
      // Fetch data only on the first render
      fetchOddsData();
      isInitialMount.current = false; // Reset after the initial call
    } else {
      const debounceTimer = setTimeout(fetchOddsData, 300); // Throttle API calls on dependency change

      return () => clearTimeout(debounceTimer); // Cleanup timer on unmount or re-render
    }
  }, [game, activeLeague, selectedBookmakers, betType]);

  const colorPalette = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8'];

  if (!game) {
    return (
     <></>
    );
  } else if (oddsData.length === 0) {
    if (selectedBookmakers.length < 1) {
      return (
        <Paper elevation={2} className={classes.componentContainer}>
          <Typography variant="subtitle1" align="left">Odds History</Typography>
          <Typography variant="h6" align="center">
            Select One or More SportsBooks
          </Typography>
        </Paper>
      );
    } else {
      return (
        <Paper elevation={2} className={classes.componentContainer}>
          <Typography variant="subtitle1" align="left">Odds History</Typography>
          <Typography variant="h6" align="center">
            No Odds Data
          </Typography>
        </Paper>
      );
    }
  }

  // Flatten and transform the data
  const transformDataForChart = (bookmakerData) => {
    const allData = [];
    const uniqueTimestamps = new Set();
  
    bookmakerData.forEach(point => {
      const { timestamp, ...bookmakerOdds } = point;
      const timestampMs = new Date(timestamp).getTime(); // Convert to Unix timestamp
  
      // Skip if the timestamp is already in the set
      if (uniqueTimestamps.has(timestampMs)) return;
      uniqueTimestamps.add(timestampMs);
  
      const transformedPoint = { timestamp: timestampMs };
      Object.keys(bookmakerOdds).forEach(bookmaker => {
        const teams = bookmakerOdds[bookmaker];
        Object.keys(teams).forEach(team => {
          if (typeof teams[team] === 'object') {
            transformedPoint[`${bookmaker}_${team}_price`] = teams[team].price;
            transformedPoint[`${bookmaker}_${team}_point`] = teams[team].point;
          } else {
            transformedPoint[`${bookmaker}_${team}_price`] = teams[team];
          }
        });
      });
  
      allData.push(transformedPoint);
    });
  
    return allData;
  };

  // Transform the data
  const chartData = transformDataForChart(oddsData);

  // Extract keys for price and point lines
  const priceKeys = Object.keys(chartData[0]).filter(key => key.endsWith('_price'));
  const pointKeys = Object.keys(chartData[0]).filter(key => key.endsWith('_point'));

  return (
    <Paper elevation={2} className={classes.componentContainer}>
      <Typography variant="h6" align="left" style={{ fontStyle: 'italic' }}>
        Odds History
        </Typography>
          
      {/* Display Loading Circle */}
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      )}

      <div className={classes.chartWrapper}>
        <ResponsiveContainer width="100%" height="100%">
        {!loading && oddsData.length > 0 && (
          <LineChart
            data={chartData}
            margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="timestamp"
              type="number"
              domain={['dataMin', 'dataMax']}
              scale="time"
              interval="preserveStartEnd"
              tickFormatter={(time) => moment(time).format('HH:mm')}
              allowDuplicatedCategory={false}
            />
            {/* Left Y Axis for price */}
            <YAxis
              yAxisId="left"
              domain={[(dataMin) => (dataMin > -100 ? -110 : dataMin - 30), (dataMax) => (dataMax < 100 ? 110 : dataMax + 30)]}
              tickFormatter={(tick) => (tick >= 100 ? `+${tick}` : tick <= -100 ? `${tick}` : '')}
            />
            {/* Right Y Axis for points */}
            <YAxis
              yAxisId="right"
              orientation="right"
              domain={['auto', 'auto']}  // Let the chart determine the point range
              tickFormatter={(tick) => tick}  // Simple formatting for points
            />
            <Tooltip labelFormatter={(time) => moment(time).format('YYYY-MM-DD HH:mm:ss')} />
            
            {/* Render the price lines */}
            {priceKeys.map((key, index) => (
              <Line
                key={key}
                yAxisId="left"  // Attach to the left Y axis
                stroke={colorPalette[index % colorPalette.length]}
                type="natural"
                dataKey={key}
                strokeWidth={3}
                dot={false}
                activeDot={{ r: 8, strokeWidth: 2, stroke: '#fff' }}
              />
            ))}

            {/* Render the point lines */}
            {pointKeys.map((key, index) => (
              <Line
                key={key}
                yAxisId="right"  // Attach to the right Y axis
                stroke={colorPalette[(index + priceKeys.length) % colorPalette.length]}
                type="natural"
                dataKey={key}
                strokeDasharray="3 4"  // Dashed line to differentiate points
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 6, strokeWidth: 2, stroke: '#fff' }}
              />
            ))}
          </LineChart>
        )}
        </ResponsiveContainer>
      </div>
    </Paper>
  );
};

export default React.memo(OddsChart);
