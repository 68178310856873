import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useRouter } from "../util/router.js"
const useStyles = makeStyles((theme) => ({
    
    button: {
        marginTop: '20px',
        backgroundColor: '#cc0000',
        color: 'white',
        padding: '10px 20px',
        border: 0
    },
}));


const DirectionButton = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
        <Button variant="outlined" className={classes.button}>Free 7 Day Trial</Button>
    </div>
  );
};

export default DirectionButton;
