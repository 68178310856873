import React, { useContext } from 'react';
import { DashboardContext } from './v3Context.js';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Paper, Typography } from '@material-ui/core';
import LeagueSelect from './LeagueSelect.jsx';

const useStyles = makeStyles((theme) => ({
  componentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    // margin: theme.spacing(2),
    width: '90%',
    height: '80vh', // Full viewport height
    textAlign: 'center',
  },
  instructionText: {
    marginBottom: theme.spacing(2),
    fontSize: '1.5rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
  },
  leagueSelectWrapper: {
    width: '80%',
    maxWidth: 500,
    padding: theme.spacing(2),
  },
}));

const FirstTimeLeagueSelect = () => {
  const classes = useStyles();
  const { fetchFromAPI, activeLeague, game, setGame, setGameData } = useContext(DashboardContext);

  return (
    <Paper elevation={2} className={classes.componentContainer}>
      <Typography variant="h4" className={classes.instructionText}>
        Step 1: Select a league
      </Typography>
        <Typography variant="body1">
         You can change this selection at any time using the input at the top of the screen. We’ll remember this selection when you return.
        </Typography>
      {/* <Card className={classes.leagueSelectWrapper}> */}
        {/* <CardContent> */}
          <LeagueSelect />
        {/* </CardContent> */}
      {/* </Card> */}
    </Paper>
  );
};

export default FirstTimeLeagueSelect;
