import React, { useEffect, useContext, useState } from 'react';
import { DashboardContext } from './v3Context.js';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, List, ListItem, Divider, Paper, Box, CircularProgress } from '@material-ui/core';
// Custom styles for the component
const useStyles = makeStyles((theme) => ({
  root: {},
    componentContainer: {
      padding: theme.spacing(.5),
      margin: theme.spacing(2),
      overflow: 'auto',
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      [theme.breakpoints.up('xl')]: {
        maxHeight: 500,
        minWidth: 400,   // Example min width for extra-large screens
        width: '48%',    // Adjusted width for extra-large screens
      },
      [theme.breakpoints.down('lg')]: {
        maxHeight: 500,
        minWidth: 500,
        width: '48%',
      },
      [theme.breakpoints.down('md')]: {
        maxHeight: 500,
        width: '50%',
      },
      [theme.breakpoints.down('sm')]: {
        maxHeight: 800,
        width: '100%',
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
      },
  },
  card: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.background.paper,
    flex: '1 1 48%',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 100%',
    },
    // maxHeight: '400px',
    overflowY: 'auto',
  },

  teamHeader: {
    // marginBottom: theme.spacing(0.5),
    textAlign: 'center',
    fontWeight: 'bold',
  },
  transactionItem: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  alternateRow: {
    backgroundColor: theme.palette.action.hover,
  },
  divider: {
    // margin: theme.spacing(0.5, 0),
  },
  icon: {
    // margin: theme.spacing(1), // Add space below icons
    // marginLeft: theme.spacing(1), // Add space between icons
    marginRight: theme.spacing(1), // Add space between icons
  },
}));

const Transactions = () => {
  const classes = useStyles();
  const { game, fetchFromAPI } = useContext(DashboardContext);

  const [homeTransactions, setHomeTransactions] = useState([]);
  const [awayTransactions, setAwayTransactions] = useState([]);
  const [homeTeam, setHomeTeam] = useState(null);
  const [homeTeamLogo, setHomeTeamLogo] = useState(null);
  const [awayTeam, setAwayTeam] = useState(null);
  const [awayTeamLogo, setAwayTeamLogo] = useState(null);

  useEffect(() => {
    if (game) {
      const home = game.homeTeam.teamName;
      const away = game.visitorTeam.teamName;
      const league = game.homeTeam.league;
      const data = { home, away, league };

      fetchFromAPI('v3/transactions', 'POST', data).then((response) => {
        setAwayTeam(response[0]);
        setAwayTeamLogo(<img src={game.visitorTeam.logoURL} alt="" width="50" height="50" />);
        setAwayTransactions(processTransactions(response[1]))
        setHomeTeam(response[2]);
        setHomeTeamLogo(<img src={game.homeTeam.logoURL} alt="" width="50" height="50" />);
        setHomeTransactions(processTransactions(response[3]))
      });
    }
  }, [game]);

  // Helper function to process the transactions
  const processTransactions = (transactionsArray) => {
    let processed = [];

    transactionsArray.forEach((transaction) => {
      let transactionText = transaction.transaction
      const timestamp = new Date(transaction.date).toLocaleDateString()
      const sentences = transactionText.split(/\. |\.$/).filter(Boolean);
      sentences.forEach((sentence) => {
        processed.push([timestamp, sentence]);
      });
    });
    return processed.sort((a, b) => b[0] - a[0]);
  };

  // Function to render a list of transactions
  const renderTransactionList = (transactions, teamLogo, side) => {
    return (
      <List>
        {transactions.map(([timestamp, sentence], index) => (
          <React.Fragment key={index}>
            <ListItem
              className={`${classes.transactionItem} ${index % 2 === 0 ? classes.alternateRow : ''}`}
            >
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <span className={classes.icon}>{teamLogo}</span>
                </div>
              <Typography variant="body1">
                {timestamp.toLocaleString()} - {sentence}
              </Typography>
            
            </ListItem>
            {index < transactions.length - 1 && <Divider className={classes.divider} />}
          </React.Fragment>
        ))}
      </List>
    );
  };

  if (game) {
    console.log('Transactions', game)
    return (
      <Paper className={classes.componentContainer}>
         <Typography variant="h6" align="left" style={{ fontStyle: 'italic' }}>
          Transactions & Injuries
        </Typography>
        {/* Visitor (Away Team) Transactions */}
        <Box display="flex" flexDirection="row" flexWrap="wrap">
          <Card className={classes.card}>
          <CardContent>
            
            {renderTransactionList(awayTransactions, awayTeamLogo, 'away')}
          </CardContent>
          </Card>

        {/* Home Team Transactions */}
        <Card className={classes.card}>
          <CardContent>
            {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span className={classes.icon}>{homeTeamLogo}</span>
            </div> */}
            {renderTransactionList(homeTransactions, homeTeamLogo, 'home')}
          </CardContent>
        </Card>
      </Box>
      </Paper>
    );
  } else {
    return <> </>
}
}

export default React.memo(Transactions)
