import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  '@global': {
    '@keyframes pulseAnimation': {
      '0%': {
        color: 'rgba(255, 255, 255, 0)',
      },
      '50%': {
        color: 'rgba(0, 0, 0, 1)', // Bright yellow
      },
      '100%': {
        color: 'rgba(255, 255, 255, 0)',
      },
    },
    '@keyframes pulseBackground': {
      '0%': {
        backgroundColor: 'rgba(0, 255, 0, 1)', // Pulsate to bright yellow
      },
      '50%': {
        backgroundColor: 'rgba(255, 255, 255, 0)', // Starting with a transparent background
      },
      '100%': {
        // backgroundColor: 'rgba(255, 255, 255, 0)', // And back to transparent
        backgroundColor: 'rgba(0, 255, 0, 1)', // Pulsate to bright yellow
      },
    },
    /* custom scrollbar for all scrollbars */
    '::-webkit-scrollbar': {
      width: '20px',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#d6dee1',
      borderRadius: '20px',
      border: '6px solid transparent',
      backgroundClip: 'content-box',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a8bbbf',
    },
  },
   root: {
    height: 'calc(100vh - 64px)', // Full height minus 64px
    minWidth: '515px',
    overflow: 'scroll',
  },
  CollectionSelectionDropDown: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '20px',
  },
  CollectionSelectionDropDownSelect: {
    marginLeft: '10px',
  },
  //Leagues Select View
  LeaguesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%', // Ensure the container takes full width
    margin: theme.spacing(1),
  },
  LeaguesCard: {
    flex: '1 1 auto',
    maxWidth: '328px',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    cursor: 'pointer',
    margin: theme.spacing(1),
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 4px 20px rgba(0,0,0,0.25)'
    }
  },
  LeaguesMedia: {
    height: 'auto',
    width: '100%' // Ensure the image takes up the full width of the card
  },

  backButton: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(-1.5),
  },
  collectionName: {
    marginBottom: theme.spacing(2),
  },
  collectionDescription: {
    marginBottom: theme.spacing(2),
  },
  editButton: {
    backgroundColor: '#1976d2',
    color: 'white',
    marginTop: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#115293',
    },
  },
  saveButton: {
    backgroundColor: '#1976d2',
    color: 'white',
    marginTop: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#115293',
    },
  },
  textField: {
    marginBottom: theme.spacing(2),
  },

  horizontalTimeLayout: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center', // Center items vertically if needed
    justifyContent: 'flex-start', // Space items evenly if needed
    width: '100%', // Ensure the container takes full width
  },
  timestamp: {
    marginRight: theme.spacing(2), // Add right margin for spacing
  },

// #region HomeFeaturesScetion Styles
featuresSection: {
  padding: theme.spacing(4, 0),
},
featureItem: {
  textAlign: 'center',
  padding: theme.spacing(2),
  [theme.breakpoints.down('xs')]: {
    textAlign: 'left',
    padding: theme.spacing(1),
  },
},
featureCard: {
  height: 150, // Set the fixed height for the card
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  border: '1px solid rgba(0, 0, 0, 0.1)', // Add a slight border
},
featureCard2: {
  height: 150, // Set the fixed height for the card
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // backgroundColor: 'transparent',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
  // boxShadow: 'none'
},
featureCard3: {
  height: 'auto', // Set the fixed height for the card
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // backgroundColor: 'transparent',
  // boxShadow: 'none',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
},
featureImage: {
  width: '100%',
  height: 'auto',
  marginTop: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  border: '1px solid rgba(0, 0, 0, 0.4)', // Add a slight border

},
// Add your custom breakpoint styles here
'@media (max-width: 122px)': {
  featureItem: {
    flexDirection: 'column',
  },
},
// #endregion

heroContainer: {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
},
imageTextContainer: {
  position: 'relative',
  display: 'inline-block',
},
heroImage5: {
  width: '60vw',
  height: 'auto',
  backgroundColor: '#e0e0e0',
  margin: theme.spacing(4),
  display: 'block',
},
heroText: {
  position: 'absolute',
  bottom: theme.spacing(5),
  left: theme.spacing(5),
  color: '#fff',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
},
featureCard5: {
  height: 250,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(0, 0, 0, 0.1)',
  width: '100%', // Ensure consistent width
},
cardContent: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%', // Ensure content takes full height
},
iconContainer: {
  height: '33%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
titleContainer: {
  height: '33%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
descriptionContainer: {
  height: '34%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
},
contentBox5: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
},
descriptionIcon: {
  marginBottom: theme.spacing(1),
},
  // #region TeamOddsRow Styles
  teamOddsRowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'left',
    marginBottom: theme.spacing(1),
    //align text to the left
    textAlign: 'right',
  },
  teamOddsTypeContainer: {
      marginRight: theme.spacing(4),
  },
  oddsItem: {
  marginRight: theme.spacing(2),
  },
  // #endregion
  // #region StatExplorer Tree View
  folderIcon: {
    color: 'orange',
    verticalAlign: 'middle',
    marginRight: '10px',
},
switchBase: {
    '&.MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: 'green',
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: 'green',
        },
    },
},
switchTrack: {
    '&.MuiSwitch-track': {
        backgroundColor: 'red', // Default color when not checked
    },
},
treeItemLabel: {
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
},
switchWrapper: {
    display: 'flex',
    alignItems: 'center',
},
  //#endregion
  
  heroSection: {
    position: 'relative',
    height: '700px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'right',
  },
  content: {
    position: 'absolute',
    top: '80%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    zIndex: 2,
    color: '#fff',
    textAlign: 'left',
  },
  video: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '700px',
    minWidth: '100%',
    width: 'auto',
    zIndex: 1,
    objectFit: 'contain',
  },

  //#region GameDetail Styles: 
  flexRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  flexItem: {
    flex: '1 1 50%',
    boxSizing: 'border-box',
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      flex: '1 1 100%',
      maxWidth: '100%',
    },
  },

  // #endregion
  accordion: {
    marginLeft: 0,
    marginRight: 0,
    marginTop: 0,
  },
  accordionDetails: {
    flexDirection: 'column',
    padding: theme.spacing(1), // or any specific value you prefer
    // Ensure the width is not affected by depth:
    width: '100%',
  },
  leftPanel: {
    width: '90px', // Adjust width as needed
    // backgroundColor: '#f0f0f0', // Just for differentiation, can be removed
  },
  rightPanel: {
    flex: 1, // Take up remaining space
    // marginLeft: '30px'
  },
  menuIcon: {
      fontSize: '50px', // Adjust the size as needed
      // padding: '8px',
      color: theme.palette.text.secondary,
      '&:hover': {
          color: theme.palette.primary.main,
      },
      transition: 'color 0.3s ease',
  },
  emergencyButton: {
    color: theme.palette.getContrastText('#ff1744'), // Adjust text color for better contrast
    marginRight: theme.spacing(2), // Add left margin for spacing
    backgroundColor: '#ff1744', // Red color
    '&:hover': {
      backgroundColor: '#ff4569', // Slightly lighter red on hover
    },
  },
  contentBox: {
    display: 'flex', // Ensures horizontal alignment of children (icon and typography)
    alignItems: 'center', // Centers items vertically within the flex container
    marginBottom: theme.spacing(2), // Adds bottom margin to each box for vertical spacing
  },
  descriptionIcon: {
    fontSize: '50px', // Adjust the size as needed
    padding: '8px',
    color: theme.palette.text.secondary,
    '&:hover': {
        color: theme.palette.primary.main,
    },
    transition: 'color 0.3s ease',
    marginRight: theme.spacing(2), // Adds right margin to icon for spacing between icon and text
  },

  pulsingIcon: {
    animation: `$pulseAnimation 1s infinite`, // Reference the animation here
  },
  iconWrapper: {
    display: 'inline-block',
    padding: theme.spacing(1), // Adjust padding as needed
    borderRadius: '50%', // Makes the background rounded, remove if not needed
    animation: '$pulseBackground 1s infinite', // Apply the pulsating animation
  },

  fullHeight: {
      height: '100%',
      overflow: 'hidden',
  },
  htmlTooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'sticky', // Make the menu sticky at the top of its container
      top: 0, // Distance from the top of the container
      height: '90vh',
      overflowY: 'auto', // Add scroll if content overflows
      zIndex: 1000,
  },
  //Modal Styles
  modalRoot: {
    height: '85vh',
    width: '85vw',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column', // Stack header and content vertically
    outline: 'none',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
  },
  modalHeaderStyle: {
    width: '100%',
    padding: theme.spacing(1, 2),
    // backgroundColor: '#f0f0f0', // Color for the header
    color: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '2px solid #ccc',
  },
  listPanel: {
    width: '30%', // Width of list panel
    height: '90%', // Adjust height based on header height
    overflowY: 'auto',
    // backgroundColor: '#f5f5f9', // Color for the list panel
    borderRight: '1px solid #ccc',
  },
  detailPanel: {
    width: '70%', // Width of detail panel
    height: '91%', // Adjust height based on header height
    overflowY: 'auto',
    padding: theme.spacing(2),
    // backgroundColor: '#fafafa', // Color for the detail panel
  },
  collectionEditDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '100%',
  },

  closeButton: {
    // Style for close button if necessary
    margin: theme.spacing(1),
  },
  toggleIcon: {
    marginRight: theme.spacing(1), // Adjust the right margin as needed
    fontSize: '2.5em'
  },

  customTwoToneColor: {
    color: 'rgba(0, 255, 0, 0.6)', // This will set the primary color
  
  },
  highlighted: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)', // A light green highlight
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)', // A bit darker on hover
    },
  },

  //Games & Game Component Styles
  gameContainer: {
    width: '90%',
    height: '80px',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    // boxShadow: '0px 5px 5px rgba(0, 0, 0, 1)',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,  
    
  },
  
  "@keyframes pulseAnimation": {
    '0%': {
      color: 'rgba(255, 255, 255, 0.5)'
    },
    
    '50%': {
      color: 'rgba(255, 255, 255, 1)'
    },

    '100%': {
      color: 'rgba(255, 255, 255, 0.5)'
    }
  },

  divider: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // '&::before': {
      //   content: '""',
    //   flex: 1,
    //   borderBottom: `1px solid #FF5733`, // Dark Orange color
    //   marginRight: theme.spacing(1),
    // },
    '&::after': {
      content: '""',
      flex: 1,
      borderBottom: `1px solid #FF5733`, // Dark Orange color
      marginLeft: theme.spacing(1),
    },
  },
  gamesSubHeader: {
    whiteSpace: 'nowrap',
    // width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    marginLeft: 'auto', // Push the Typography to the left
  },
  card: {
    backgroundColor: theme.palette.background.paper,  
    // backgroundCol or: '#333',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
  team: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  score: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.primary,
    textAlign: 'center',
    
  },
  //TeamOddsRow Styles
  league: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
  },

  teamName: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },

  oddsLabel: {
    fontWeight: 'bold',
  },
  oddsValue: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
  },

  date: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },

  tooltip: {
    padding: '10px',
    border: '1px solid #ccc',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  
  //App Demo Section
  appDemoContainer: {
    margin: theme.spacing(2),
    display: 'flex',
    height: '50%'  // Adjust height as necessary
  },
  appDemoTextSide: {
    flex: 1,  // Takes up 1/3 of the space
    padding: "20px"  // Adjust padding as needed
  },
  appDemoVideoSide: {
    flex: 1,  // Takes up 2/3 of the space
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',  // Hide any overflow if video is too big
    width: '100%', // Ensure the container's width is constrained
  },
  // video: {
  //   margin: 'auto',  // Center the video
  //   maxHeight: '60vh',  // Limit the height of the video to 30% of the viewport height
  //   width: 'auto',  // Adjust width automatically to maintain aspect ratio
  //   maxWidth: '100%',  // Ensure the width does not exceed the container width
  //   boxSizing: 'border-box', // Includes padding and border in the element's total width and height
  //   objectFit: 'contain' // Ensures the video is scaled properly within its box
  // },
  '@media (max-width: 768px)': {
    appDemoContainer: {
      flexDirection: 'column'
    },
    appDemoVideoSide: {
      flex: 'none', // Reset flex-grow to distribute space equally
      width: '100%',  // Each section takes full width of the screen
      maxHeight: '50vh'  // Optional: Adjust max height in mobile view if needed
    },
    appDemoTextSide: {
      flex: 'none', // Reset flex-grow to distribute space equally
      width: '100%'  // Each section takes full width of the screen
    }
  }
  
}));
