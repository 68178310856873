import React from "react";
import LandingHeader1 from "../LandingComponents/LandingHeader1";
import IconsVideoComponent from "../LandingComponents/IconsVideoComponent";
import GoogleTag from '../components/GoogleTag';
import analytics from "./../util/analytics";
import ParallaxComponent from "../LandingComponents/ParallaxComponent";
import MultiTextParallaxComponent from "../LandingComponents/MultiTextParallaxComponent";
import LandingFooter from "../LandingComponents/LandingFooter";

function LandingPage(props) {

  React.useEffect(() => {
    <GoogleTag />
    analytics.page();
  }, []);

  return (
    <div style={{
      width: '100vw',
      // height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '1200px',
      margin: '0 auto',
    }}>
        <GoogleTag />
        <LandingHeader1 />
        <IconsVideoComponent />
        <MultiTextParallaxComponent imageSrc={'/images/NavigatorComponentBase.png'} texts={['Easy To Navigate Stat Library', 'Definitions and Insights for Hundreds of Stats']} id="6" aligns={['left', 'right']}/>
        <MultiTextParallaxComponent imageSrc={'/images/SummaryComponentBase.png'} texts={['Ongoing and Upcoming Games Summarized', '1 Minute Delay For Onging Games']} id="5" aligns={['right', 'left']}/>
        <MultiTextParallaxComponent imageSrc={'/images/DetailComponentBase.png'} texts={['Historical Odds', 'Custom Reports from Your Unlimited Stat Collections']} id="4" aligns={['right', 'left']}/>
        <ParallaxComponent imageSrc={'/images/DownloadComponentBase.png'} text={'Download Customized Stat Reports in CSV, XLSX, & JSON'} id="2" align="right" />
        <LandingFooter size="normal"
                  bgImage="/images/landingFooterbg.png"
                  bgImageOpacity={1}
                  description="StatFactory.io is a sports data analytics platform that provides tools for sports bettors, fantasy sports players, and sports fans to make data-driven decisions."
                  copyright={`© ${new Date().getFullYear()} StatFactory.io`}
                  color="default"
                  logo='./images/statFactory-Logo.png'
                  logoInverted="/images/statFactory-Logo-dark.png"
                  sticky={true}
          />
    </div>
  );
}

export default LandingPage;
