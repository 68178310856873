import React, { useContext } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { DashboardContext } from "./v3Context.js";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
  },
  select: {
    borderRadius: 8,
    // backgroundColor: '#fff',
    // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    // padding: theme.spacing(1.5),
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
  selectLabel: {
    marginBottom: theme.spacing(1),
    fontWeight: 600,
    color: '#333',
  },
  menuItem: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  checkbox: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  },
  listItemText: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },
}));

const LeagueSelect = () => {
  const classes = useStyles();
  const { leagues, activeLeague, setActiveLeague } = useContext(DashboardContext);

  const handleChange = (event) => {
    setActiveLeague(event.target.value);
  };

  if (leagues) {
    return (
      <FormControl className={classes.formControl}>
      <InputLabel id="league-select-label" className={classes.selectLabel}>Select A League</InputLabel>
      <Select
        className={classes.select}
        labelId="league-select-label"
        id='league-select'
        value={activeLeague || ''}
        onChange={handleChange}
        displayEmpty
      >
        {leagues.map((league, index) => (
          <MenuItem key={index} value={league} className={classes.menuItem}>
            {league}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    );
  } else {
    return null;
  }
};



export default LeagueSelect;
