import React, { useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import GameSchedule from "./GameSchedule.jsx";
import DataExplorer from "./DataExplorer.jsx";
import ComparisonTable from "./ComparisonTable.jsx";
import StatLineChart from "./StatLineChart.jsx";
import OddsChart from "./OddsChart.jsx";
import Transactions from "./Transactions.jsx";
import LineShop from "./LineShop.jsx";
import { DashboardContext } from "./v3Context.js";
import BetCalc from "./BetCalc.jsx";
import StickyNote from "./StickyNote.jsx";
import FirstTimeLeagueSelect from "./FirstTimeSelectLeague.jsx";

const useStyles = makeStyles((theme) => ({
  componentsContainer: {
    padding: theme.spacing(2),
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    alighItems: 'center', 
    
  },
  modalContainer: {
    position: 'relative',
    width: '85vw',
    height: '85vh',
    margin: 'auto',
    marginTop: '5vh',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    outline: 'none',  // Removes the default focus outline from the modal
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',  // Dark overlay background
  },
}));

const ToolboxLayout = () => {
  const classes = useStyles();
  const { isDictionaryOpen, setDictionaryOpen, activeLeague } = useContext(DashboardContext);

  const handleClose = () => {
    setDictionaryOpen(false);  // Close the modal when clicking outside or pressing the close button
  };

  return (
    <div className={classes.componentsContainer}>
      {/* Modal for DataExplorer */}
      <Modal
        open={isDictionaryOpen}
        onClose={handleClose}  // Close modal on outside click
        className={classes.backdrop}
      >
        <div className={classes.modalContainer}>
          {/* Close Button (X) */}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          {/* Modal Content: DataExplorer */}
          <DataExplorer />
        </div>
      </Modal>

      {!activeLeague ? (
        <FirstTimeLeagueSelect />
      ) : (
        <>
        <GameSchedule />
        <Transactions />
        <ComparisonTable />
        {/* <CollectionManager /> */}
        <StatLineChart />
        {/* <StickyNote /> */}
        <LineShop />
        <OddsChart />
        {/* <BetCalc /> */}
        {/* <EventTimeline />  */}
        </>
      )
      
      }
      </div>
  );
};

export default ToolboxLayout;
